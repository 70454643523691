@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn{
        @apply py-2 px-3 uppercase rounded shadow text-sm transition font-medium duration-300;
    }

    .default-btn {
        @apply bg-gray-400 text-white hover:bg-gray-600;
    }

    .primary-btn {
        @apply bg-sky-400 text-white hover:bg-sky-600;
    }
}
